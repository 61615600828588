import * as $ from 'jquery'
import 'slick-carousel';

$('.slider').slick({
    autoplay:true,
    autoplaySpeed:3000,
    dots:false,
    fade:true,
    speed:2000,
    pauseOnHover:false
});
