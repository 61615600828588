(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Payjp"));
	else if(typeof define === 'function' && define.amd)
		define(["Payjp"], factory);
	else if(typeof exports === 'object')
		exports["MyLibrary"] = factory(require("Payjp"));
	else
		root["MyLibrary"] = factory(root["Payjp"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__438__) {
return 