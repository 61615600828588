import * as $ from 'jquery';
import 'jquery-validation';
import * as Payjp from 'Payjp';

function getPayjpPublicKey() {
    return process.env.PAYJP_PUBLIC_KEY;
}

async function postRegisterMember(params: any) {
    const query_params = new URLSearchParams({action: "register", ...params});
    const response = await fetch(process.env.GAS_API_ENDPOINT + '?' + query_params, {
        method: "GET",
    })
    const result = await response.json();

    switch (result.status) {
        case "200":
            return result.data;
            break;
        case "400":
        case "422":
            throw new Error(result.message);
            break;
        default:
            throw new Error('予期せぬエラーが起こりました');
    }
}

function setupBirthDate() {

    const select_year: any = $('#select_year');
    const select_month: any = $('#select_month');
    const select_day: any = $('#select_day');
    const birthday: any = $('#birthday');

    function $set_birthday(){
        const value = `${select_year.val()}-${select_month.val()}-${select_day.val()}`;
        birthday.val(value);
    }
    function $set_year(){
        let op = $('<option>').val('').text('年');
        select_year.append(op);
        let i;
        // 年を生成(100年分)
        for(i = 1919; i < 2020; i++){
            let op = $('<option>')
                .val(i)
                .text(i);
            select_year.append(op);
        }
    }
    function $set_month(){
        let op = $('<option>').val('').text('月');
        select_month.append(op);
        let i;
        // 月を生成(12)
        for(i = 1; i <= 12; i++){
            let op = $('<option>')
                .val(i)
                .text(i);
            select_month.append(op);
        }
    }

    function $set_day(){
        let i;
        //日の選択肢を空にする
        select_day.html('');
        // 日を生成(動的に変える)
        if(select_year.val() !== '' &&  select_month.val() !== ''){
            const last_day = new Date(select_year.val(), select_month.val(),0).getDate()
            let op = $('<option>').val('').text('日');
            select_day.append(op);
            for (i = 1; i <= last_day; i++) {
                let op = $('<option>')
                    .val(i)
                    .text(i);
                select_day.append(op);
            }
        }
    }

    $set_year();
    $set_month();
    $set_day();
    select_year.on('change', () => {
        $set_day();
        $set_birthday();
    });
    select_month.on('change', () => {
        $set_day();
        $set_birthday();
    });
    select_day.on('change', () => {
        $set_birthday();
    });
}

$(function () {

    $('.msf:first').each((index, form) => {

        var $form = $(form);

        // 誕生日入力
        setupBirthDate();

        // カード発行
        const $cardIssuanceBox: any = $('#card-issuance-box');
        const changeCardIssuance = (cardIssuance: boolean) => {
            if (cardIssuance) {
                $cardIssuanceBox.show();
            } else {
                $cardIssuanceBox.hide();
            }
        }
        const $cardIssuance: any = $('#card_issuance');
        $cardIssuance.on('change', (element) => {
            changeCardIssuance(element.currentTarget.checked);
        });

        var val = {
            rules: {
                name: {
                    required: true,
                    maxlength: 100
                },
                furigana: {
                    required: true,
                    maxlength: 100
                },
                nickname: {
                    required: true,
                    maxlength: 100
                },
                mail: {
                    required: true,
                    email: true
                },
                password: {
                    required: false,
                    minlength: 6,
                    maxlength: 100
                },
                postNumber: {
                    required: true,
                    maxlength: 100
                },
                address: {
                    required: true,
                    maxlength: 100
                }
            },
            // Specify validation error messages
            messages: {
                name: {
                    required: "必須です",
                    maxlength: "100文字以内で入力してください",
                },
                furigana: {
                    required: "必須です",
                    maxlength: "100文字以内で入力してください",
                },
                nickname: {
                    required: "必須です",
                    maxlength: "100文字以内で入力してください",
                },
                mail: {
                    required: "必須です",
                    email: "メールアドレスの形式が不正です",
                },
                password: {
                    required: "必須です",
                    minlength: "6文字以上で入力してください",
                    maxlength: "100文字以内で入力してください",
                },
                postNumber: {
                    required: "必須です",
                    maxlength: "100文字以内で入力してください",
                },
                address: {
                    required: "必須です",
                    maxlength: "100文字以内で入力してください",
                },
            }
        };
        $form.multiStepForm({
            allowClickNavigation: true,
            allowUnvalidatedStep: false,
            hideBackButton: false
        });

        var validated = $form.validate(val);

        $(document).on("msf:viewChanged", function (event, data) {
            $("html, body").animate({scrollTop: $('.application-form-block').offset().top}, "fast");
        });

        // 公開鍵を登録し、起点となるオブジェクトを取得します
        var payjp = Payjp(getPayjpPublicKey())
        var elements4 = payjp.elements()

        var numberElement = elements4.create('cardNumber', {
            style: {
                base: {
                    backgroundColor: '#F4F4F4',
                    fontFamily: 'YuMincho, ヒラギノ明朝 Pro, sans-serif;'
                },
            },
            placeholder: ''
        });
        var expiryElement = elements4.create('cardExpiry', {
            style: {
                base: {
                    backgroundColor: '#F4F4F4',
                    fontFamily: 'YuMincho, ヒラギノ明朝 Pro, sans-serif;'
                },
            }
        });
        var cvcElement = elements4.create('cardCvc', {
            style: {
                base: {
                    backgroundColor: '#F4F4F4',
                    fontFamily: 'YuMincho, ヒラギノ明朝 Pro, sans-serif;'
                },
            },
            placeholder: ''
        });
        numberElement.mount('#number-form')
        expiryElement.mount('#expiry-form')
        cvcElement.mount('#cvc-form')

        $('#form').submit(async function (event) {
            event.preventDefault();

            if (!validated.checkForm()) {
                return false;
            }

            var h = $(window).height();

            $('#loading ,#spinner').height(h).css('display', 'block');

            const $form = $(this);
            const $button = $form.find('button');
            try {
                $button.attr('disabled', true);

                const formArray = $form.serializeArray();
                const returnArray = {};
                for (var i = 0; i < formArray.length; i++) {
                    returnArray[formArray[i]['name']] = formArray[i]['value'];
                }

                // カード発行希望
                returnArray['cardIssuance'] = $('#card_issuance').prop("checked");

                // payjpの処理
                const r = await payjp.createToken(numberElement);
                returnArray['card'] = r.id;

                await postRegisterMember(returnArray);

                // エラーメッセージ非表示
                $('.error').addClass('d-none');

                location.href = "/thanks.html";
                return false;

            } catch (e) {
                $('.error').removeClass('d-none').text(e.message);
            } finally {
                $('#loading').delay(500).fadeOut(500);
                $('#spinner').delay(300).fadeOut(300);
                $button.attr('disabled', false);

                $("html, body").animate({scrollTop: $('.application-form-block').offset().top}, "slow");
                return false;
            }
        })
    })
});

// library
const msfCssClasses = {
    header: "msf-header",
    step: "msf-step",
    statuses: {
        stepComplete: "msf-step-complete",
        stepIncomplete: "msf-step-incomplete",
        stepActive: "msf-step-active"
    },
    content: "msf-content",
    view: "msf-view",
    navigation: "msf-navigation",
    navButton: "msf-nav-button"
};

const msfNavTypes = {
    back: "back",
    next: "next",
    submit: "submit"

};

const msfJqueryData = {
    validated: "msf-validated",
    visited: "msf-visited"
};

const msfEventTypes = {
    viewChanged: "msf:viewChanged"
};

$.fn.multiStepForm = function(options) {
    var form = this;

    var defaults = {
        activeIndex: 0,
        validate: {},
        hideBackButton: false,
        allowUnvalidatedStep: false,
        allowClickNavigation: false
    };

    var settings = $.extend({}, defaults, options);

    //find the msf-content object
    form.content = this.find("." + msfCssClasses.content).first();

    if (form.content.length === 0) {
        throw new Error('Multi-Step Form requires a child element of class \'' + msfCssClasses.content + '\'');
    }

    //find the msf-views within the content object
    form.views = $(this.content).find("." + msfCssClasses.view);

    if (form.views.length === 0) {
        throw new Error('Multi-Step Form\'s element of class \'' + msfCssClasses.content + '\' requires n elements of class \'' + msfCssClasses.view + '\'');
    }

    form.header = this.find("." + msfCssClasses.header).first();
    form.navigation = this.find("." + msfCssClasses.navigation).first();
    form.steps = [];
    //form.completedSteps = 0;

    form.getActiveView = function() {
        return form.views.filter(function() {
            return this.style && this.style.display !== '' && this.style.display !== 'none'
        });
    };

    form.setActiveView = function(index) {
        var previousView = form.getActiveView()[0];
        var previousIndex = form.views.index(previousView);

        $(previousView).hide();
        //if(previousView)
        //    previousView.hide();

        var view = form.views.eq(index);
        view.show();
        // view.find(':input').first().focus();

        var completedSteps = 0;
        $.each(form.views, function(index, view) {
            if ($.data(view, msfJqueryData.validated)) {
                completedSteps++;
            }
        });

        //trigger the 'view has changed' event
        form.trigger(msfEventTypes.viewChanged, {
            currentIndex: index,
            previousIndex: previousIndex,
            totalSteps: form.steps.length,
            completedSteps: completedSteps
        });
    }

    form.setStatusCssClass = function(step, cssClass) {
        $(step).removeClass(msfCssClasses.statuses.stepComplete);
        $(step).removeClass(msfCssClasses.statuses.stepIncomplete);

        $(step).addClass(cssClass);
    }

    form.tryNavigateToView = function(currentIndex, targetIndex) {
        if (targetIndex <= currentIndex) {

            form.validateView(form.views[currentIndex]);

            if(!settings.hideBackButton)
                form.setActiveView(targetIndex);
            return;
        }

        if (!form.validateViews(currentIndex, targetIndex - currentIndex, function(i) {
            if (!settings.allowUnvalidatedStep) {
                form.setActiveView(i);
                return false;
            }

            return true;
        })) {
            if (!settings.allowUnvalidatedStep) {
                return;
            }
        }
        form.setActiveView(targetIndex);
    }

    form.init = function() {

        this.initHeader = function() {
            if (form.header.length === 0) {
                form.header = $("<div/>", {
                    "class": msfCssClasses.header,
                    "display": "none"
                });

                $(form).prepend(form.header);
            }

            form.steps = $(form.header).find("." + msfCssClasses.step);

            this.initStep = function(index, view) {

                //append steps to header if they do not exist
                if (form.steps.length < index + 1) {
                    $(form.header).append($("<div/>", {
                        "class": msfCssClasses.step,
                        "display": "none"
                    }));
                }

                if (settings.allowClickNavigation) {
                    //bind the click event to the header step
                    $(form.steps[index]).click(function(e) {
                        var view = form.getActiveView()[0];
                        var currentIndex = form.views.index(view);
                        var targetIndex = form.steps.index($(e.target).closest("." + msfCssClasses.step)[0]);

                        form.tryNavigateToView(currentIndex, targetIndex);
                    });
                }
            }

            $.each(form.views, this.initStep);

            form.steps = $(form.header).find("." + msfCssClasses.step);
        };


        this.initNavigation = function() {

            if (form.navigation.length === 0) {
                form.navigation = $("<div/>", {
                    "class": msfCssClasses.navigation
                });

                $(form.content).after(form.navigation);
            }

            this.initNavButton = function(type) {
                var element = this.navigation.find("button[data-type='" + type + "'], input[type='button']"),
                    type;
                if (element.length === 0) {
                    element = $("<button/>", {
                        "class": msfCssClasses.navButton,
                        "data-type": type,
                        "html": type
                    });
                    element.appendTo(form.navigation);
                }
                return element;
            };

            form.backNavButton = this.initNavButton(msfNavTypes.back);
            form.nextNavButton = this.initNavButton(msfNavTypes.next);
            form.submitNavButton = this.initNavButton(msfNavTypes.submit);
        };

        this.initHeader();
        this.initNavigation();

        this.views.each(function(index, view) {

            $.data(view, msfJqueryData.validated, false);
            $.data(view, msfJqueryData.visited, false);

            //if this is not the last view do not allow the enter key to submit the form as it is not completed yet
            if (index !== form.views.length - 1) {
                $(view).find(':input').not('textarea').keypress(function(e) {
                    if (e.which === 13) // Enter key = keycode 13
                    {
                        form.nextNavButton.click();
                        return false;
                    }
                });
            }

            $(view).on('show', function(e) {
                if (this !== e.target)
                    return;

                var view = e.target;
                $.data(view, msfJqueryData.visited, true);

                var index = form.views.index(view);
                var step = form.steps[index];

                $(step).addClass(msfCssClasses.statuses.stepActive);
                //form.setStatusCssClass(step, msfCssClasses.statuses.stepActive);

                //choose which navigation buttons should be displayed based on index of view
                if (index > 0 && !settings.hideBackButton) {
                    form.backNavButton.show();
                }

                if (index == form.views.length - 1) {
                    form.nextNavButton.hide();
                    form.submitNavButton.show();
                }
                else {
                    form.submitNavButton.hide();
                    form.nextNavButton.show();
                }
            });

            $(view).on('hide', function(e) {
                if (this !== e.target)
                    return;

                var index = form.views.index(e.target);
                var step = form.steps[index];

                $(step).removeClass(msfCssClasses.statuses.stepActive);

                if ($.data(e.target, msfJqueryData.validated) && $.data(e.target, msfJqueryData.visited)) {
                    form.setStatusCssClass(step, msfCssClasses.statuses.stepComplete);
                }
                else if ($.data(e.target, msfJqueryData.visited)) {
                    form.setStatusCssClass(step, msfCssClasses.statuses.stepIncomplete);
                }
                else {
                    form.setStatusCssClass(step, "");
                }

                //hide all navigation buttons, display choices will be set on show event
                form.backNavButton.hide();
                form.nextNavButton.hide();
                form.submitNavButton.hide();
            });

            //initially hide each view
            $(view).hide();
        });


        if (settings.activeIndex > 0) {
            $(form).ready(function() {
                form.tryNavigateToView(0, settings.activeIndex);
            });
        }
        else {
            form.setActiveView(0);
        }

    };

    form.validateView = function(view) {
        var index = form.views.index(view);

        if (form.validate().subset(view)) {
            $.data(view, msfJqueryData.validated, true);
            form.setStatusCssClass(form.steps[index], msfCssClasses.statuses.stepComplete);
            return true;
        }
        else {
            $.data(view, msfJqueryData.validated, false);
            form.setStatusCssClass(form.steps[index], msfCssClasses.statuses.stepIncomplete);
            return false;
        }
    };

    form.validateViews = function(currentIndex, length, invalid) {
        currentIndex = typeof currentIndex === 'undefined' ? 0 : currentIndex;
        length = typeof length === 'undefined' ? form.views.length : length;


        var validationIgnore = ""; // Saving the existing validator ignore settings to reset them after validating multi-step form
        var isValid = true;

        //remember original validation setings for ignores
        if ($(form).data("validator")) {
            var formValidatorSettings = $(form).data("validator").settings;
            validationIgnore = formValidatorSettings.ignore;

            var currentValidationIgnoreSettingsArray = validationIgnore.split(",");
            if (currentValidationIgnoreSettingsArray.length >= 1) {
                // Remove the ":hidden" selector from validator ignore settings as we want our hidden fieldsets/steps to be validated before final submit
                var hiddenIndex = $.inArray(":hidden", currentValidationIgnoreSettingsArray);
                currentValidationIgnoreSettingsArray.splice(hiddenIndex, 1);
                $(form).data("validator").settings.ignore = currentValidationIgnoreSettingsArray.toString();
            }
        }

        for (var i = currentIndex; i < currentIndex + length; i++) {
            if (!form.validateView(form.views[i])) {
                isValid = false;

                if (!invalid(i)) {
                    break;
                }
            }
        }

        if ($(form).data("validator")) {
            $(form).data("validator").settings.ignore = validationIgnore;
        }

        return isValid;
    }

    form.init();

    form.nextNavButton.click(function() {
        var view = form.getActiveView()[0];
        var index = form.views.index(view);

        if (form.validateView(view)) {
            form.setActiveView(index + 1);
        }
        else if (settings.allowUnvalidatedStep) {
            form.setActiveView(index + 1);
        }
    });

    form.backNavButton.click(function() {
        var view = form.getActiveView()[0];
        var index = form.views.index(view);

        form.validateView(view);

        form.setActiveView(index - 1);
    });

    form.submit(function(e) {
        var validationIgnore = "";

        form.validateViews(0, form.views.length, function() {
            e.preventDefault();
            return true;
        });
    });
    return form;
};

$.validator.prototype.subset = function(container) {
    var ok = true;
    var self = this;
    $(container).find(':input').each(function() {
        if (!self.element($(this))) ok = false;
    });
    return ok;
};

$.each(['show', 'hide'], function(i, ev) {
    var el = $.fn[ev];
    $.fn[ev] = function() {
        this.trigger(ev);
        return el.apply(this, arguments);
    };
});
