import * as $ from 'jquery';

$(function(){
    const ua = navigator.userAgent;
    if (ua.indexOf('iPhone') > -1 || (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1)) {
        $('a[href^="#"]').click(function(){
            var speed = 1000;
            var href= $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top;
            $("html, body").animate({scrollTop:position}, speed, "swing");
            return false;
        });
    } else if (ua.indexOf('iPad') > -1 || ua.indexOf('Android') > -1) {
        $('a[href^="#"]').click(function(){
            var speed = 1000;
            var href= $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top;
            $("html, body").animate({scrollTop:position}, speed, "swing");
            return false;
        });
    } else {
        $('a[href^="#"]').click(function(){
            var speed = 1000;
            var href= $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top-80;
            $("html, body").animate({scrollTop:position}, speed, "swing");
            return false;
        });
    }
    $(".tell-link").click(function() {
        $(this).find("p.text-free").fadeOut();
        $(this).find("p.link-block-title").fadeOut();
        $(this).find("div.tell-link-block").fadeIn();
    });
});
